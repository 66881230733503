<template />
<script>
export default {
    props:['comments'],
	data(){
		return{
			message:null,
			type:'place',
			markers:'',
			images: '',
			isMounted: false,
			center: {
				lat : 0,
  				lng : 0,
			},
			markers : this.markers,
		}
	},
   
};
</script>