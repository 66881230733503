<template>
  <div class="card bg-secondary shadow border-0">
    <div class="card-header bg-white ">
      <div class="text-muted text-center ">
        <small
          v-if="errors"
          class="text-danger font-weight-bold"
        > * {{ errors }} <br></small>
        <h6>Sign in with</h6>
      </div>
      <div class="btn-wrapper text-center">
        <!-- <a
          href="#"
          class="btn btn-neutral btn-icon"
        
        >
          <span class="btn-inner--icon">
            <i class="fa fa-facebook" />
          </span>
          <span class="btn-inner--text">Facebook</span>
        </a> -->
        <!-- <facebook-login class="button fb_button"
      appId="248865543947647"
      @login="userLoggedIn"
  
      @get-initial-status="fdgfd">
    </facebook-login> -->
       
        <a
          href="#"
          class="btn btn-neutral btn-icon"
          @click="AuthProvider('facebook')"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-facebook" />
          </span>
          <span class="btn-inner--text">Facebook</span>
        </a>
       
      </div>
    </div>
    <div class="card-body ">
      <div class="text-center text-muted mb-4">
        <h6>Or sign in with credentials</h6>
      </div>
      <form role="form">
        <div class="form-group mb-3">
          <div class="input-group input-group-alternative">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-envelope" /></span>
            </div>
            <input
              v-model="email"
              class="form-control"
              placeholder="Email"
              type="email"
            >
          </div>
        </div>
        <div class="form-group">
          <div class="input-group input-group-alternative">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-unlock-alt" /></span>
            </div>
            <input
              v-model="password"
              class="form-control"
              placeholder="Password"
              type="password"
              
            >
          </div>
        </div>
        <div class="custom-control custom-control-alternative custom-checkbox">
          <input
            id=" customCheckLogin2"
            class="custom-control-input"
            type="checkbox"
          >
          <label
            class="custom-control-label"
            for=" customCheckLogin2"
          >
            <span>Remember me</span>
          </label>
        </div>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-primary mt-4"
            @click="login"
          >
            Sign in
          </button>
        </div>
      </form>
    </div>
    <p class="text-center">
      Don't Have An Account ?  <router-link
        class="font-weight-bold"
        to="/sign-up"
        style="display:inline !important;"
      >
        SIGN-UP NOW
      </router-link>
    </p>
    <p class="text-center">
       Forgot your Password ?
       <router-link
        class="font-weight-bold"
        to="/forgot-password"
        style="display:inline !important;"
      >
     RESET NOW
      </router-link>
    </p>
  </div>
</template>
<script>
// import axios from 'axios';
import {axios} from '../services/http-common';

export default {
   
	data(){
		return{
			email:'',
			password:'',
      errors:null,
		}
	},
  mounted(){

  },
 
	methods:{
   
		login(){
			let obj = {};
			obj.password = this.password;
			obj.email = this.email;
			axios
        .post(`${process.env.VUE_APP_API_URL}login`,obj)
        .then(response => {
            if (!response.error) {
              localStorage.setItem('token', response.data.message.token);
              localStorage.setItem('role', response.data.message.user_role);
              localStorage.setItem('name', response.data.message.user_name);
              if( typeof response.data.message.image !== 'undefined')
              localStorage.setItem('image', response.data.message.image);
              localStorage.setItem('user_id', response.data.message.user_id);
              localStorage.setItem('zipcode', response.data.message.zipcode);
              window.location.href = "/profile";
            }
        })
        .catch( error => {
          if(error.response)
            this.errors = error.response.data.message;
        });
		},
    AuthProvider(provider) {
      var self = this;
      this.$auth.authenticate(provider).then(response =>{
        console.log('Provide is:',provider);
        console.log('Response is:',response);

        self.SocialLogin(provider,response)
        }).catch(err => {
          // alert('Catch');
            console.log({err:err})
        })
    },
    SocialLogin(provider,response){
      return  axios
          .post(`${process.env.VUE_APP_API_URL}fb/login`,response)
          .then(response => {
              if (!response.error) {
                if (!response.error) {
              localStorage.setItem('token', response.data.message.token);
              localStorage.setItem('role', response.data.message.user_role);
              localStorage.setItem('name', response.data.message.user_name);
              if( typeof response.data.message.image !== 'undefined')
              localStorage.setItem('image', response.data.message.image);
              localStorage.setItem('user_id', response.data.message.user_id);
              localStorage.setItem('zipcode', response.data.message.zipcode);
              //  $('.fb_button').click();
              window.location.href = "/profile";
            }
              }
          });
    },
  }
};
</script>
