<template>
  <slick
    :options="slickOptions"
    class="listing-slider mfp-gallery-container margin-bottom-0"
  >
    <template v-for="(gallery,index) in images">
      <div :key="index">
        <a
          :style="{'background-image': 'url('+ storageUrl + gallery.image + ')', backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition: 'center center'}"
          class="item mfp-gallery"
        />
      </div>
    </template>
  </slick>
</template>

<script>
import Slick from "vue-slick";
export default {
	components:{
		Slick
	},
	props: ['images'],
	data(){
		return{
			storageUrl: process.env.VUE_APP_STORAGE_URL,
		}
	},
	computed: {
		slickOptions() {
			return {
	         centerMode: true,
	        //  centerPadding: '20%',
	         slidesToShow: 2,
	         responsive: [
	            {
	              breakpoint: 1367,
	              settings: {
	                centerPadding: '15%'
	              }
	            },
	            {
	              breakpoint: 1025,
	              settings: {
	                centerPadding: '0'
	              }
	            },
	            {
	              breakpoint: 767,
	              settings: {
	                centerPadding: '0',
	                slidesToShow: 1
	              }
	            }
	         ]  
			}
		},
	},
	
};
</script>
