<template>
  <div class="main-wrapper">
     <template>
     <loader v-if="isLoaded"
     object="#3370b7" 
     color1="#ffffff" 
     color2="#17fd3d" 
     size="5"
     speed="2" 
     bg="#ffffff" 
     objectbg="#cfcfcf" 
     opacity="80"
      disableScrolling="false"
     name="spinning"></loader>
    </template>
    <gallery-slider
      v-if="isMounted"
      :key="gallery_key"
      :images="images"
    />
    <!-- Content -->
    <div class="container">
      <div class="content">
        <div class="row sticky-wrapper">
          <div class="col-md-8 col-lg-8 mb-3">
            <div class="">
              <button @click="goBack" class="btn btn-success btn-sm"><i class="fas fa-arrow-left"></i>  Back</button>
              <div class="detail-tile mb-4">
                <h4>
                  {{ details.name }} <span class="badge badge-pill badge-success text-uppercase"> <a
                    :href="details.specials"
                    target="_blank"
                  >See Special</a></span>
                </h4>
                <p class="text-muted">
                  <i
                    class="fa fa-map-marker"
                    aria-hidden="true"
                    style="color:#e4486d"
                  />
                  {{ details.address_1 }}, {{ details.city }}, {{ details.state }} {{ details.zipcode }}
                </p>

                <span
                  v-if="$isAuth"
                  data-toggle="modal"
                  data-target="#addhh"
                  style="font-size: 13px;cursor: pointer;border-radius:0% !important;"
                  class="pt-1 pb-1 font-weight-bold badge-pill badge-success text-uppercase mr-2"
                > 
                  <i
                    class="fa fa-edit"
                    aria-hidden="true"
                  />  Happy Hour Review </span>
							
                <span
                  v-else
                  data-toggle="modal"
                  data-target="#login"
                  style="font-size: 13px;cursor: pointer;border-radius:0% !important;"
                  class="pt-1 pb-1 font-weight-bold badge-pill badge-success text-uppercase mr-2"
                > 
                  <i
                    class="fa fa-edit"
                    aria-hidden="true"
                  />  Happy Hour Review </span>

                <span v-if="$isAuth">
                  <label
                    for="images"
                    style="font-size: 13px;cursor: pointer;border-radius:0% !important;"
                    class="pt-1 pb-1 font-weight-bold badge-pill badge-primary text-uppercase mr-2"
                  >
                    <i
                      class="fa fa-camera"
                      aria-hidden="true"
                    /> Add Happy Hour Photo</label>
                  <input
                    id="images"
                    type="file"
                    accept="image/png, image/jpeg"
                    style="display:none !important;"
                    @change="onFileChange"
                  ></span>					
                <span
                  v-else
                  data-toggle="modal"
                  data-target="#login"
                  style="font-size: 13px;cursor: pointer;border-radius:0% !important;"
                  class="pt-1 pb-1 font-weight-bold badge-pill badge-primary text-uppercase mr-2"
                > 
                  <i
                    class="fa fa-camera"
                    aria-hidden="true"
                  /> Add Happy Hour Photo </span>

                <span
                  v-if="$isAuth"
                  data-toggle="modal"
                  data-target="#report"
                  style="font-size: 13px;cursor: pointer;border-radius:0% !important;"
                  class="pt-1 pb-1 font-weight-bold badge-pill badge-danger text-uppercase mr-2"
                > 
                  <i
                    class="fa fa-ban"
                    aria-hidden="true"
                  /> Report </span>
                <span
                  v-else
                  data-toggle="modal"
                  data-target="#login"
                  style="font-size: 13px;cursor: pointer;border-radius:0% !important;"
                  class="pt-1 pb-1 font-weight-bold badge-pill badge-danger text-uppercase mr-2"
                > 
                  <i
                    class="fa fa-ban"
                    aria-hidden="true"
                  /> Report </span>
                  <span
                   v-if="$isAuth"
                    :id="details.id"
                    @click="like(details.id)"
                    class="like-button"
                  > 
                  <i class="fa fa-thumbs-up" /> {{details.total_likes}}
                 </span>
                  <span
                   v-else
                    data-toggle="modal"
                      data-target="#login"
                    class="like-button"
                  > 
                  <i class="fa fa-thumbs-up" /> {{details.total_likes}}
                 </span>
              </div>
              <div class="nav-wrapper mb-4">
                <ul
                  id="tabs-icons-text"
                  class="nav nav-pills nav-fill flex-column flex-md-row"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      id="tabs-icons-text-1-tab"
                      class="nav-link mb-sm-3 mb-md-0 active show"
                      data-toggle="tab"
                      href="#tabs-icons-text-4"
                      role="tab"
                      aria-controls="tabs-icons-text-1"
                      aria-selected="true"
                    ><i class="fa fa-money mr-2" />  Happy Hour Reviews</a>
                  </li>
                  <li class="nav-item">
                    <a
                      id="tabs-icons-text-2-tab"
                      class="nav-link mb-sm-3 mb-md-0"
                      data-toggle="tab"
                      href="#tabs-icons-text-2"
                      role="tab"
                      aria-controls="tabs-icons-text-2"
                      aria-selected="false"
                    ><i class="fa fa-map-marker mr-2" />Location</a>
                  </li>
                </ul>
              </div>
              <div
                class="card shadow"
                style="max-height: 600px;margin: 0em;overflow-y: auto;"
              >
                <div class="card-body">
                  <div
                    id="myTabContent"
                    class="tab-content"
                  >
                    <div
                      id="tabs-icons-text-2"
                      class="tab-pane fade"
                      role="tabpanel"
                      aria-labelledby="tabs-icons-text-2-tab"
                    >
                      <!-- <p class="description">Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p> -->
                      <div>
                        <GmapMap
                          :center="center"
                          :zoom="7"
                          map-type-id="terrain"
                          style="width: 100%; height: 350px"
                        >
                          <GmapMarker
                            v-for="(m, index) in markers"
                            :key="index"
                            :position="m.position"
                            :clickable="true"
                            :draggable="true"
                            @click="center=m.position"
                          />
                        </GmapMap>
                      </div>
                    </div>
                    <div
                      id="tabs-icons-text-4"
                      class="tab-pane fade active show"
                      role="tabpanel"
                      aria-labelledby="tabs-icons-text-4-tab"
                    >
                      <div v-if="comments && comments.length > 0">
                        <div
                          v-for="(comment,index) in comments"
                          :key="index"
                          class="row mb-5 list-img-wrap"
                        > 
                          <div class="col-md-2 list-img">
                            <img
                              v-if="comment['user']['userable']['image']"
                              class="img-fluid rounded-circle shadow-lg"
                              :src="baseUrl +comment['user']['userable_type'] +'s/'+comment['user']['userable']['image']"
                              style="height:70px;width:70px"
                            >
                            <img
                              v-else
                              class="img-fluid rounded-circle shadow-lg"
                              src="/static/images/man.png"
                            >
                          </div>
                          <div class="col-md-10">
                            <h5 class="text-primary">
                              {{ comment['user']['user_name'] }}
                            </h5>
                            <p class="text-muted">
                              {{ new Date(comment.created_at).toLocaleDateString('en-US', { month: 'long', year: 'numeric', day: '2-digit'}) }}
                            </p>
                            <p style="max-width:415px;">
                              {{ comment.comment }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else
                        class="text-danger font-weight-bold"
                      >
                        No Happy Hour Review Found
                      </div>
                    </div>
                    <!-- <CommentList :comments="comments" :key="componentKey"/> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-4 col-lg-4">
					<sidebar-layout-two></sidebar-layout-two>
				</div> -->
        </div>
      </div>
    </div>
    <!-- Review Model  -->
    <div
      id="addhh"
      ref="modal"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="HHModalLabel"
      style="margin-top:100px;"
    >
      <div
        class="modal-dialog "
        role="document"
      >
        <div class="modal-content">
          <div
            class="modal-header"
            style="background-color:currentColor; "
          >     
            <h6 style="color:white;width:100%">
              Mask Required?  &nbsp;Vaccine Required? 
              <br>
               Take Out? &nbsp;Walk-Ins? &nbsp;Seating?
            </h6>
            
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                style="color:white;"
                aria-hidden="true"
              >&times;</span>
            </button>
          </div> 
          <div class="modal-body text-center">
            <div class="cw-3"> 
              <form class="text-left">
                <div class="row">
                  <div
                    class="col-md-12 mt-3"
                    style="float:right"
                  >
                    <textarea
                      id="comment_area"
                      ref="commentInput"
                      v-model="message"
                      name=""
                      cols="30"
                      rows="3"
                      class="form-control"
                      placeholder=" Review"
                    />
                  </div>
                  <div
                    class="col-md-12 mt-3"
                    style="float:right"
                  >
                    <div
                      class="col-md-4 mt-3"
                      style="float:right"
                    >
                      <button
                        type="button"
                        class="btn btn-sm btn-primary btn-padded w-100 f-700 text-uppercase"
                        @click="postReview"
                      >
                        submit
                      </button>
                    </div>  
                  </div>   
                </div>            
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- //login modal -->
    <div
      id="login"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="HHModalLabel"
      style="margin-top:90px;"
    >
      <div
        class="modal-dialog "
        role="document"
      >
        <div class="modal-content">
          <div
            class="modal-header"
            style="background-color:currentColor;padding: 6px 6px 6px 18px !important;"
          >     
            <h5 style="color:white;">
              Login
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                style="color:white;"
                aria-hidden="true"
              >&times;</span>
            </button>
          </div> 
          <loginModal />
        </div>
      </div>
    </div>

    <!-- report modal  -->
    <div
      id="report"
      ref="report_modal"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="HHModalLabel"
      style="margin-top:100px;"
    >
      <div
        class="modal-dialog "
        role="document"
      >
        <div class="modal-content">
          <div
            class="modal-header"
            style="background-color:currentColor;"
          >     
            <h5 style="color:white;">
              Report
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                style="color:white;"
                aria-hidden="true"
              >&times;</span>
            </button>
          </div> 
          <div class="modal-body text-center">
            <div>
              <h5
                class="f-700 text-uppercase mb-0 mt-2"
                style="padding-bottom: 5% !important;"
              >
                THANK YOU FOR HELPING US
             
                <span>Maintain our LIstings</span>
              </h5>
              <form class="text-left">
                <div> 
                  <label class="container">
                
                    <input
                      ref="special"
                      v-model="obj.checked"
                      type="checkbox"
                      name="checked"
                    >
                    Enter link to add/update happy hour specials
                    <div
                      v-if="obj.checked"
                      class="form-group"
                    >
                      <input
                        id="specials"
                        ref="special_link"
                        v-model="obj.specials"
                        type="url"
                        required
                        name="specials"
                        placeholder="http://happyhour.com"
                        class="form-control themeInput"
                        style="opacity:100;position:relative;width:24vw;height:6vh;"
                      >
                    </div>
                
                    <span class="checkmark" />
                  </label>
                </div>
                <div v-if="reportFor == 'liveMusic'"> 
                  <label class="container">
                    <input
                      v-model="obj.inaccurate_days"
                      type="checkbox"
                    >
                    <span class="checkmark" />
                    Inaccurate Days of Live Music
                  </label>
                </div>
                <label class="container">
                  <input
                    ref="is_closed"
                    v-model="obj.is_closed"
                    type="checkbox"
                  >
                  <span class="checkmark" />
                  Business closed
                </label>
                <label class="container">
                  <input
                    ref="is_hateful"
                    v-model="obj.is_hateful"
                    type="checkbox"
                  >
                  <span class="checkmark" />
                  Hateful, sexual, fake inappropriate
                </label>
                <button
                  type="button"
                  class="btn btn-primary btn-padded w-100 f-700 text-uppercase"
                  @click="handleSubmit(obj)"
                >
                  SUbmit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GallerySlider from 'Components/globalFrontendComponents/GallerySlider';
import SidebarLayoutTwo from 'Views/listing/SidebarLayoutTwo';
import CommentList from '../components/CommentList';
import loginModal from '../components/LoginModal.vue';
// import axios from 'axios';
import {axios} from '../services/http-common';

export default {
	components: {
		GallerySlider: GallerySlider,
		// SidebarLayoutTwo: SidebarLayoutTwo,
		// CommentList,
		loginModal,
	},
	data(){
		return{
			obj:{
				is_closed:'',
				is_hateful:''
			},
      isLoaded:true,
			baseUrl:process.env.VUE_APP_BASE_URL,
			message:null,
			componentKey:0,
			type:'place',
			reportFor:'happy_hour',
			markers:'',
            details: '',
			comments: '',
			gallery_key: 1,
			slug:'',
			images: '',
			isMounted: false,
			apiBaseURL: process.env.VUE_APP_API_URL,
			center: {
				lat : 0,
  				lng : 0,
			},
			markers : this.markers,
		}
	},
    mounted(){
		$(this.$refs.modal).on('hidden.bs.modal', () => {
			this.comment = ''
			this.$refs.commentInput.value = ''
   	 	})
		$(this.$refs.report_modal).on('hidden.bs.modal', () => {
		
			this.$refs.special_link.value = ''
			this.$refs.special.checked = ''
			this.$refs.is_closed.checked = ''
			this.$refs.is_hateful.checked = ''
			this.obj.checked = false
   	 	})
		
        this.slug = this.$route.params.slug;
        axios
            .get(`${process.env.VUE_APP_API_URL}search-place/slug/`+this.slug)
            .then(response => {
               if (!response.error) {
                	this.images =     response.data.data.images;
                	this.details = 	  response.data.data;
                	this.comments =   response.data.data.comments;
                  document.title = response.data.data.name +' - Thriggle';
					this.center.lat = parseFloat(response.data.data.lat);
					this.center.lng = parseFloat(response.data.data.long);
					this.isMounted = true;
					this.setEventsMarkers();
          this.isLoaded = false;
              	}
            });
    },
	methods:{
    like(id){
         let data = {};
         data.likeable_type = 'place';
         data.likeable_id = id;
         return axios
            .post( `${process.env.VUE_APP_API_URL}likes`,data)
            .then(response => {
            if(!response.error) {
               $('#'+id).html('<i class="fa fa-thumbs-up"></i> ' + response.data.data.total_likes);
            }
         });
      },
    goBack(){
     this.$router.go(-1);
    },
	handleSubmit(obj) {
		if(this.reportFor == 'happy_hour'){
			this.report('/updatep/');
		}
	},
	report(type){
		return axios
      .put(type + this.details.id, this.obj)
      .then(response => {
         $("#report").modal('hide');
      })
      .catch(error => {
        console.log('Errro !');
      });
	},
	onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("place_id", this.details.id);
      let self = this;
      var url = '';
      if(this.$route.name == 'happy-hour')
        url = 'upload/place-image';
      else
        url = 'upload/place-image'
      axios
        .post(this.apiBaseURL + url, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          
        })
        .then(function(res) {
			self.images = res.data.images;
			self.gallery_key = self.gallery_key + 1;
			self.$toast.success("Image Added !", {
				position: "bottom-right",
				timeout: 2508,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: true,
				closeButton: "button",
				icon: true,
				rtl: false,
				top:'300px',
			});
		})
        .catch(function(error) {
			console.log('Error is:', error);
			if(error.response){
				self.$toast.error(error.response.data.image[0], {
					position: "bottom-right",
					timeout: 2508,
					closeOnClick: true,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
					draggable: true,
					draggablePercent: 0.6,
					showCloseButtonOnHover: false,
					hideProgressBar: true,
					closeButton: "button",
					icon: true,
					rtl: false,
					top:'300px',
				});
			}
		});
    },
	postReview(){
    if(this.message){
		    let obj = {};
          obj.comment = this.message;
          obj.commentable_id = this.details.id;
          obj.commentable_type = this.type;
          obj.created_at = new Date();

		   axios
            .post(`${process.env.VUE_APP_API_URL}comments`,obj)
            .then(response => {
               if (!response.error) {
				   this.comments = response.data.data;
				   $("#addhh").modal('hide');
              	}
            });
    }
    else{
      alert('Review Required');
    }
	},
	setEventsMarkers() {
		let event = this.details;
		let markersArray = [];

		this.markers = [];
		this.center = {
			lat:  parseFloat(this.center.lat),
			lng:  parseFloat(this.center.lng),
		};
		if (event) {
			let marker = {
				lat: parseFloat(event.lat),
				lng: parseFloat(event.long),
			};
			markersArray.push({
				position: marker,
				currentEventInfo: event,
				label: "1",
				infoText: "Marker #1",
			});
			this.markers = markersArray;
		}
	},
	}
};
</script>
<style scoped>
.like-button{
  font-size: 13px;
  cursor: pointer;
  background-color:#5e72e4;
  color:#ffffff;
  padding:13px 10px 13px 10px;
  border-radius:50%
}
</style>
