<template>
  <div class="sticky">
    <!-- Book Now -->
    <div class="boxed-widget booking-widget">
      <div>
        <div class="form-group focused">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="ni ni-calendar-grid-58" /></span>
            </div>
            <input
              class="form-control"
              placeholder="Start date"
              type="text"
              value="06/18/2018"
            >
          </div>
        </div>
        <select class="custom-select mb-3">
          <option selected>
            Guest
          </option>
          <option value="1">
            One
          </option>
          <option value="2">
            Two
          </option>
          <option value="3">
            Three
          </option>
          <option value="4">
            Four
          </option>
        </select>
      </div>
      <!-- Book Now -->
      <router-link
        to="/pages/booking"
        class="btn btn-1 btn-primary d-block"
      >
        Book Now
      </router-link>
    </div>
    <!-- Book Now / End -->
    <div class="boxed-widget bg-secondary mt-4 text-center p-4">
      <h4 class="mb-4">
        Key People
      </h4>
      <div class="mb-3">
        <div class="mb-2">
          <img
            class="img-fluid rounded-circle shadow-lg"
            width="80"
            height="80"
            src="/static/images/thumb-1.jpg"
          >
        </div>
        <div class="">
          <h5 class="text-primary">
            Victoria Benson
          </h5>
        </div>
      </div>
      <ul class="listing-details-sidebar">
        <li><i class="fa fa-phone" /> (123) 123-456</li>
        <li><i class="fa fa-external-link" /> http://example.com</li>
        <li><i class="fa fa-envelope-o" />victoria@victoria.com</li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {

};
</script>
